import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

export interface QFormControlProps extends FormControlProps {
  label?: string;
  error?: string;
  helper?: string;
}

export const QFormControl: React.FC<QFormControlProps> = (props) => {
  const { label, error, helper, ...rest } = props;
  return (
    <FormControl {...rest}>
      {!!label && (
        <FormLabel fontWeight="semibold" fontSize="sm">
          {label}
        </FormLabel>
      )}
      {props.children}
      {!rest.isInvalid && <FormHelperText fontSize="xs">{helper}</FormHelperText>}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
