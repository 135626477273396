import React from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
} from '@chakra-ui/react';
import { QButtonProps, QIcon, QText } from '../..';
import { IconNameType } from '../../types/Icons';

export type QMenuItemType = {
  id: string;
  label: string;
};

export interface QMenuButtonDefaultProps
  extends Pick<MenuProps, 'onClose' | 'isOpen'> {
  buttonLabel: string;
  iconName?: IconNameType;
  variant?: QButtonProps['variant'] | 'icon';
  itemSize?: 'xs' | 'sm' | 'md' | 'lg';
  isDisabled?: boolean;
}

type QMenuButtonWithPropsType = {
  items: readonly QMenuItemType[];
  onItemClick: (item: QMenuItemType) => void;
} & QMenuButtonDefaultProps;

type QMenuButtonWithCompositeType = {
  children: React.ReactNode;
} & QMenuButtonDefaultProps;

export type QMenuButtonProps =
  | QMenuButtonWithPropsType
  | QMenuButtonWithCompositeType;

export const QMenuButton: React.FC<QMenuButtonProps> = (props) => {
  const {
    buttonLabel,
    variant = 'solid',
    itemSize = 'sm',
    iconName,
    children,
    isOpen,
    isDisabled,
    onClose,
  } = props;

  const onItemClick = 'onItemClick' in props ? props.onItemClick : undefined;
  const items = 'items' in props ? props.items : undefined;

  const menuItems = (items: readonly QMenuItemType[]) =>
    items.map((item) => {
      const { id, label, ...otherParams } = item;
      return (
        <MenuItem
          {...otherParams}
          key={id}
          onClick={onItemClick ? () => onItemClick(item) : undefined}
        >
          <QText fontSize={itemSize}>{label}</QText>
        </MenuItem>
      );
    });

  if (variant === 'icon') {
    return (
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          items={items}
          isDisabled={isDisabled}
          as={IconButton}
          aria-label={buttonLabel}
          icon={<QIcon iconName={iconName ?? 'MoreVertical'} />}
          variant="ghost"
        >
          {children}
        </MenuButton>
        <MenuList>{items ? menuItems(items) : children}</MenuList>
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant={variant}
        isDisabled={isDisabled}
        rightIcon={<QIcon iconName={iconName ?? 'ChevronDown'} />}
      >
        {buttonLabel}
      </MenuButton>
      <MenuList>{items ? menuItems(items) : children}</MenuList>
    </Menu>
  );
};
