import { Alert } from './alert';
import { Badge } from './badge';
import { Button } from './button';
import { Table } from './table';
import { Tabs } from './tabs';
import { Tag } from './tag';
import { Text } from './text';
import { Spinner } from './spinner';
import { Switch } from './switch';
import { Input } from './input';
import { Heading } from './heading';
import { Divider } from './divider';
import { Checkbox } from './checkbox';
import { Radio } from './radio';
import { CloseButton } from './closeButton';
import { FormLabel } from './form';
import { Textarea } from './textarea';
import { Drawer } from './drawer';
import { Stat } from './stat';
import { Modal } from './modal';

export const overrides = {
  components: {
    Alert,
    Badge,
    Button,
    Drawer,
    Table,
    Tabs,
    Tag,
    Text,
    Modal,
    Spinner,
    Switch,
    Input,
    Heading,
    Divider,
    Checkbox,
    Radio,
    CloseButton,
    FormLabel,
    Textarea,
    Stat,
  },
};
