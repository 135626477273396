import { Tag } from '@chakra-ui/react';
import { QText } from '../Text/Text';
import React from 'react';
import { DataProps } from '../../utils';

export type QTagProps = {
  variantColor?:
    | 'default'
    | 'blue'
    | 'green'
    | 'red'
    | 'yellow'
    | 'orange'
    | 'invertedOrange'
    | 'purple'
    | 'pink'
    | 'gray';
  maxWidth?: string;
} & DataProps;

export const QTag: React.FC<QTagProps> = ({
  variantColor = 'default',
  maxWidth,
  children,
  ...rest
}) => {
  return (
    <Tag
      variant={variantColor === 'default' ? 'outline' : variantColor}
      size="sm"
      maxWidth={maxWidth}
      {...rest}
    >
      {maxWidth ? <QText isTruncated>{children}</QText> : children}
    </Tag>
  );
};
QTag.displayName = 'QTag';
