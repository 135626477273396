import React, { useMemo } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalProps,
} from '@chakra-ui/react';
import { QButtonProps } from '../../QAtoms';
import { useChakraContainer } from '../../hooks/chakraContainer/chakraContainer';
import { getChildrenOfType } from '../..';

export type QModalButtonProps = QButtonProps & { label?: string };

export type QModalProps = Pick<
  ModalProps,
  'size' | 'isOpen' | 'onClose' | 'trapFocus' | 'autoFocus'
>;

export const QModal: React.FC<QModalProps> = (props) => {
  const {
    size = 'md',
    isOpen,
    onClose,
    children,
    trapFocus,
    autoFocus = true,
  } = props;
  const { chakraContainerRef } = useChakraContainer();
  const footer = useMemo(() => {
    const actions = getChildrenOfType(children, 'QModalActions');
    return actions.length > 0 ? <ModalFooter>{actions}</ModalFooter> : null;
  }, [children]);

  return (
    <>
      <Modal
        size={size}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        portalProps={{ containerRef: chakraContainerRef }}
        trapFocus={trapFocus}
        autoFocus={autoFocus}
      >
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent>
          {getChildrenOfType(children, 'QModalHeader')}
          {getChildrenOfType(children, 'QModalBody')}
          {footer}
        </ModalContent>
      </Modal>
    </>
  );
};
