import React from 'react';
import format from 'date-fns/fp/format';
import { WrapItem } from '@chakra-ui/react';
import { useCurrentUser } from '../../hooks';
import { QText } from '../../QAtoms';

const formatDateWithtime = format("do LLL yyyy 'at' p");
const formatDateOnly = format('do LLL yyyy');

export type QMetadataBaseItemProps<T> = {
  label: string;
  value: T | null | undefined;
};

/**
 * An arbitrary label and string value pair.
 */
export const QStringMetadataItem: React.VFC<QMetadataBaseItemProps<string>> = ({
  label,
  value,
}) => {
  if (!value) {
    return null;
  }
  return (
    <WrapItem>
      <QText fontSize="sm" fontWeight={400}>
        {label}:&nbsp;
      </QText>
      <QText fontSize="sm" fontWeight={600}>
        {value}
      </QText>
    </WrapItem>
  );
};
QStringMetadataItem.displayName = 'QStringMetadataItem';

type QDateMetadataItemProps = QMetadataBaseItemProps<Date> & {
  withTime?: boolean;
};

/**
 * And arbitrary label and date value pair.
 *
 * Relies on the `useCurrentUser` hook to localise the date.
 *
 * @param withTime - Whether to include the time in the formatted date.
 */
export const QDateMetadataItem: React.VFC<QDateMetadataItemProps> = ({
  label,
  value,
  withTime,
}) => {
  const { localiseDate } = useCurrentUser();
  if (!value) {
    return null;
  }
  const format = withTime ? formatDateWithtime : formatDateOnly;
  return (
    <QStringMetadataItem label={label} value={format(localiseDate(value))} />
  );
};
QDateMetadataItem.displayName = 'QDateMetadataItem';

export type QMetadataProps<T = string> = Pick<
  QMetadataBaseItemProps<T>,
  'value'
>;

export const QVersionMetadata: React.VFC<QMetadataProps> = ({ value }) => (
  <QStringMetadataItem label="Version" value={value} />
);
QVersionMetadata.displayName = 'QVersionMetadata';

export const QOwnerMetadata: React.VFC<QMetadataProps> = ({ value }) => (
  <QStringMetadataItem label="Owner" value={value} />
);
QOwnerMetadata.displayName = 'QOwnerMetadata';

export const QCreatedAtMetadata: React.VFC<QMetadataProps<Date>> = ({
  value,
}) => <QDateMetadataItem label="Created" value={value} withTime />;
QCreatedAtMetadata.displayName = 'QCreatedAtMetadata';

export const QLastModifiedMetadata: React.VFC<QMetadataProps<Date>> = ({
  value,
}) => <QDateMetadataItem label="Last modified" value={value} withTime />;
QLastModifiedMetadata.displayName = 'QLastModifiedMetadata';

export const QDueMetadata: React.VFC<QMetadataProps<Date>> = ({ value }) => (
  <QDateMetadataItem label="Due" value={value} />
);
QDueMetadata.displayName = 'QDueMetadata';
