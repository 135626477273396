import React from 'react';
import {
  HTMLChakraProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
} from '@chakra-ui/react';
import { QIcon } from '../../QIcons/Icon';
import { QCloseButton } from '../CloseButton/CloseButton';
import { IconNameType } from '../../types/Icons';
import InputMask from 'react-input-mask';

type Omitted = 'disabled' | 'required' | 'readOnly' | 'size';
export interface QInputProps
  extends Omit<HTMLChakraProps<'input'>, Omitted>,
    Pick<
      InputProps,
      | 'isInvalid'
      | 'isDisabled'
      | 'value'
      | 'placeholder'
      | 'isReadOnly'
      | 'onChange'
    > {
  iconLeftName?: IconNameType;
  isClearable?: boolean;
  mask?: string;
}

const useFallbackRef = (forwardedRef) =>
  React.useState(() => forwardedRef || React.createRef())[0];

const QInput: React.ForwardRefRenderFunction<HTMLInputElement, QInputProps> = (
  props,
  ref,
) => {
  const { iconLeftName, isClearable, mask, onChange, ...rest } = props;
  const clearableRef = useFallbackRef(ref);

  const optionalMaskProps: Pick<QInputProps, 'mask' | 'as'> = mask
    ? { mask, as: InputMask }
    : {};

  const handleClearInputClick = React.useCallback(
    (event) => {
      if (clearableRef !== null) {
        clearableRef.current.value = '';
        event.target = clearableRef.current; // needed so that onChange correctly returns the new value of ""
        onChange?.(event);
      }
    },
    [clearableRef],
  );

  return (
    <InputGroup size="sm">
      {iconLeftName && (
        <InputLeftElement pointerEvents="none" color="gray.400">
          <QIcon iconName={iconLeftName} />
        </InputLeftElement>
      )}
      <Input
        {...rest}
        {...optionalMaskProps}
        onChange={onChange}
        size="sm"
        ref={clearableRef}
      />
      {isClearable && (
        <InputRightElement color="gray.400">
          <QCloseButton onClick={handleClearInputClick} size="sm" />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default React.forwardRef<HTMLInputElement, QInputProps>(QInput);
