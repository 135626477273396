import { Icon } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import * as icons from 'react-icons/fi';
import * as bsIcons from 'react-icons/bs';
import { IconNameType } from '../types/Icons';
import { CheckCircleFilled } from './CheckCircleFilled';
import { OneDrive } from './OneDrive';
import { QualioAI } from './QualioAI';

export type QIconProps = {
  iconName: IconNameType;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: string;
};

function getSize(variant?: string) {
  switch (variant) {
    case 'md':
      return 5;
    case 'lg':
      return 6;
    default:
      return 4;
  }
}
export const QIcon: React.FC<QIconProps> = (props) => {
  const { size, iconName, ...rest } = props;
  switch (iconName) {
    case 'CheckCircleFilled':
      rest['width'] = rest['width'] | getSize(size);
      rest['height'] = rest['height'] | getSize(size);
      return <CheckCircleFilled {...rest} />;
    case 'OneDrive':
      rest['width'] = rest['width'] | getSize(size);
      rest['height'] = rest['height'] | getSize(size);
      return <OneDrive {...rest} />;
    case 'QualioAI':
      rest['width'] = rest['width'] | getSize(size);
      rest['height'] = rest['height'] | getSize(size);
      return <QualioAI {...rest} />;
    case 'FillCheckCircleFill':
    case 'ExclamationCircleFill':
    case 'FillExclamationTriangleFill':
    case 'FillXCircleFill':
      return (
        <Icon
          {...rest}
          w={getSize(size)}
          h={getSize(size)}
          as={bsIcons[`Bs${iconName}`]}
        />
      );
    default:
      const icon = icons[`Fi${iconName}`];
      return <Icon {...rest} w={getSize(size)} h={getSize(size)} as={icon} />;
  }
};

QIcon.displayName = 'QIcon';

export const getIcon = (iconName: IconNameType): IconType => {
  switch (iconName) {
    case 'FillCheckCircleFill':
    case 'ExclamationCircleFill':
    case 'FillExclamationTriangleFill':
    case 'FillXCircleFill':
      return bsIcons[`Bs${iconName}`];
    default:
      return icons[`Fi${iconName}`];
  }
};
