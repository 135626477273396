import React from 'react';
import { QFlex } from '../../../QLayouts';
import { getChildrenOfType } from '../../../utils';

export const QSelectPlaceholder: React.FC = ({ children }) => {
  const icon = getChildrenOfType(children, 'QIcon');
  const text = getChildrenOfType(children, 'QText');

  return text.length ? (
    <QFlex alignItems={'center'}>
      {icon.length ? <QFlex mr={1}>{icon}</QFlex> : null}
      {text}
    </QFlex>
  ) : null;
};

QSelectPlaceholder.displayName = 'QSelectPlaceholder';
