import React from 'react';
import type { RowData } from '@tanstack/react-table';
import format from 'date-fns/fp/format';
import { useCurrentUser } from '../../../hooks';
import { QText } from '../../../QAtoms';
import { CellProps } from './types';

const formatDate = format('dd MMM yyyy');
const formatDateTime = format('dd MMM yyyy HH:mm');

/**
 * Displays a date in the user's selected Qualio-timezone.
 */
export function QDateCell<TData extends RowData>({
  getValue,
  column,
}: CellProps<TData, Date>): React.ReactElement {
  const { localiseDate } = useCurrentUser();
  const fmt = column.columnDef.meta?.date?.includeTime
    ? formatDateTime
    : formatDate;
  return <QText>{fmt(localiseDate(getValue()))}</QText>;
}
