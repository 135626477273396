import colors from '../../QTheme/colors';
export const Tag = {
  baseStyle: {
    container: {
      color: 'blue',
      bg: 'white',
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: '10px',
        fontWeight: 'semibold',
      },
    },
  },
  variants: {
    outline: {
      container: {
        color: 'gray.700',
        boxShadow: `inset 0 0 0px 1px ${colors.gray['200']}`,
      },
    },
    invertedOrange: {
      container: {
        color: 'orange.50',
        bg: 'orange.500',
      },
    },
    gray: {
      container: {
        color: 'gray.900',
        bg: 'gray.100',
      },
    },
    blue: {
      container: {
        color: 'blue.900',
        bg: 'blue.100',
      },
    },
    green: {
      container: {
        color: 'green.900',
        bg: 'green.100',
      },
    },
    red: {
      container: {
        color: 'red.900',
        bg: 'red.100',
      },
    },
    yellow: {
      container: {
        color: 'yellow.900',
        bg: 'yellow.100',
      },
    },
    orange: {
      container: {
        color: 'orange.900',
        bg: 'orange.100',
      },
    },
    purple: {
      container: {
        color: 'purple.900',
        bg: 'purple.100',
      },
    },
    pink: {
      container: {
        color: 'pink.900',
        bg: 'pink.100',
      },
    },
  },
};
