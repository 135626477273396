import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerProps,
  DrawerFooter,
} from '@chakra-ui/react';
import { QCloseButton } from '../../QAtoms';
import { QStack } from '../../QLayouts';
import { useChakraContainer } from '../../hooks/chakraContainer/chakraContainer';
import { excludeChildrenOfType, getChildrenOfType } from '../..';
import { QDrawerHeader } from './DrawerHeader';

export interface QDrawerProps
  extends Pick<DrawerProps, 'size' | 'onClose' | 'isOpen' | 'variant'> {
  /**
   * @deprecated
   * Please use `QDrawerHeader` instead.
   */
  title?: string | React.ReactElement;
}

export const QDrawer: React.FC<QDrawerProps> = (props) => {
  const { isOpen, onClose, title, size = 'md', children, variant } = props;
  const { chakraContainerRef } = useChakraContainer();
  const header = getChildrenOfType(children, 'QDrawerHeader');
  const footer = getChildrenOfType(children, 'QDrawerFooter');

  const trapFocus = variant !== 'clickThrough';

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={size}
      autoFocus={false}
      closeOnOverlayClick={false}
      variant={variant}
      blockScrollOnMount={true}
      trapFocus={trapFocus}
      portalProps={{ containerRef: chakraContainerRef }}
    >
      <DrawerContent>
        <DrawerHeader>
          <QStack direction="row" alignItems="end" spacing="4px">
            {getChildrenOfType(children, 'QBadge')}
          </QStack>
          {header.length ? (
            header
          ) : (
            // To avoid breaking changes, we need to support the old title prop.
            <QDrawerHeader>
              {title}
              <QCloseButton onClick={onClose} />
            </QDrawerHeader>
          )}
        </DrawerHeader>
        <DrawerBody>
          {excludeChildrenOfType(
            children,
            'QBadge',
            'QDrawerHeader',
            'QDrawerFooter',
          )}
        </DrawerBody>
        {footer.length > 0 && <DrawerFooter padding={0}>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
};
