import React from 'react';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';

export type QCheckboxProps = Pick<
  CheckboxProps,
  | 'size'
  | 'isDisabled'
  | 'isChecked'
  | 'isInvalid'
  | 'isIndeterminate'
  | 'value'
  | 'onClick'
  | 'onChange'
  | 'isReadOnly'
>;

export const QCheckbox: React.FC<QCheckboxProps> = (props) => {
  const { size = 'sm' } = props;

  return <Checkbox {...props} size={size} />;
};
