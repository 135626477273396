import React, { useRef } from 'react';
import { ToastId, useToast as useChakraToast } from '@chakra-ui/react';
import { QToast, QToastProps } from '../..';

export type ToastContextType = {
  showToast: (options: QToastProps) => void;
};

const ToastContext = React.createContext<ToastContextType | undefined>(
  undefined,
);

export const useToastProvider = (): ToastContextType => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('useToast can only be used inside ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC = ({ children }) => {
  const toast = useChakraToast();

  const composite = useCompositeToastIds();

  const showToast = (options: QToastProps) => {
    const { duration = 5000, id, replace, ...rest } = options;

    if (replace) {
      /* Trying to close a toast then immediately open a new one
       * with the same id will not work, because the same
       * toast id will be considered a duplicate until the old toast
       * has fully closed. To work around this, we increment the
       * id of the toast we want to replace, so that it is considered
       * a new toast.
       */
      toast.close(composite.idFor(id));
      composite.incrementIdFor(id);
    } else if (id && toast.isActive(composite.idFor(id))) {
      console.log('Suppressing duplicate toast', id);
      return;
    }

    const toastId = id ? composite.idFor(id) : undefined;

    toast({
      id: toastId,
      position: 'top-right',
      duration: duration,
      render: ({ onClose }) => (
        <chakra-scope>
          <QToast {...rest} onClose={onClose} />
        </chakra-scope>
      ),
      onCloseComplete: () => composite.clearRecordFor(id, toastId),
    });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
    </ToastContext.Provider>
  );
};

type IdRecord = Record<ToastId, number>;

type CompositeToastId = `${ToastId}§${number}`;

const useCompositeToastIds = () => {
  const idRecord = useRef({} as IdRecord);

  /**
   * Generates a composite id for a given toast id.
   */
  const idFor = (id: ToastId): CompositeToastId =>
    `${id}§${idRecord.current[id] ?? 1}`;

  /**
   * Increments the record for a given id.
   * Returns nothing.
   */
  const incrementIdFor = (id: ToastId): void => {
    idRecord.current[id] = (idRecord.current[id] ?? 1) + 1;
  };

  /**
   * Makes a best effort to clear the record for a given id,
   * if the current id matches the one given (i.e. is the newest).
   */
  const clearRecordFor = (
    id: ToastId | undefined,
    composite: CompositeToastId | undefined,
  ) => {
    if (id && composite && idFor(id) === composite) {
      delete idRecord.current[id];
    }
  };

  return { idFor, incrementIdFor, clearRecordFor, idRecord };
};
