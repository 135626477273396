import { extendTheme } from '@chakra-ui/react';

import { fonts } from './typography';
import colors from './colors';
import { overrides } from './overrides';

export const QTheme = extendTheme({
  styles: {
    global: {
      html: {
        fontSize: '16px',
      },
      body: {
        color: 'gray.900',
      },
    },
  },
  fonts,
  colors,
  ...overrides,
});

export * from './QualioQThemeProvider';

export type QualioQTheme = typeof QTheme;
