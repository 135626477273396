import colors from '../../QTheme/colors';
import { QColorVariant, QColorRgba, QColors } from '../../QTypes';

const boxShadowColors: {
  [key in QColorVariant]: QColorRgba;
} = {
  gray: 'rgba(54, 56, 66, 0.12)',
  blue: 'rgba(29, 36, 115, 0.12)',
  green: 'rgba(0, 71, 48, 0.12)',
  red: 'rgba(102, 0, 5, 0.12)',
  yellow: 'rgba(92, 61, 0, 0.12)',
  orange: 'rgba(115, 54, 0, 0.12)',
  purple: 'rgba(50, 29, 115, 0.12)',
  pink: 'rgba(115, 29, 95, 0.12)',
};

const variants = QColors.reduce((obj, color) => {
  obj[color] = {
    container: {
      bg: `${color}.50`,
      color: `${color}.500`,
      _hover: {
        boxShadow: `0px 16px 48px ${boxShadowColors[color]}`,
      },
      _selected: {
        boxShadow: `0px 16px 48px ${boxShadowColors[color]}`,
      },
    },
    number: {
      color: `${color}.900`,
    },
    label: {
      color: `${color}.900`,
    },
  };
  return obj;
}, {});

export const Stat = {
  parts: ['container', 'icon', 'label', 'number'],
  baseStyle: {
    container: {
      paddingX: '16px',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderRadius: '4px',
      minWidth: '176px',
      bg: 'gray.50',
      color: 'gray.500', // This colors the nested <QIcon>
    },
    number: {
      fontWeight: '700',
      lineHeight: '32px',
    },
    label: {
      fontWeight: '600',
    },
  },
  defaultProps: {
    variant: 'gray',
  },
  variants: {
    ...variants,
    outline: {
      container: {
        bg: 'white',
        color: 'gray.900',
        outline: '1px solid',
        outlineColor: 'gray.200',
      },
    },
    ghost: {
      container: {
        color: 'gray.900',
        bg: 'white',
        border: '0px',
      },
    },
  },
  colors,
};
