import React from 'react';
import {
  AsyncSelect,
  AsyncProps,
  SingleValue,
  MultiValue,
} from 'chakra-react-select';
import { baseProps } from './base';
import { QSelectItem } from './types';
import { usePartitionedChildren } from '../../utils';

type QGroup<TLabel extends React.ReactChild, TValue> = {
  options: QSelectItem<TLabel, TValue>[];
  label: string;
};

interface QAsyncSelectBaseProps<
  IsMulti extends boolean,
  TLabel extends React.ReactChild,
  TValue,
> extends Pick<
    AsyncProps<QSelectItem<TLabel, TValue>, IsMulti, QGroup<TLabel, TValue>>,
    | 'isDisabled'
    | 'isInvalid'
    | 'size'
    | 'isClearable'
    | 'name'
    | 'isLoading'
    | 'noOptionsMessage'
    | 'isSearchable'
    | 'defaultValue'
    | 'isOptionDisabled'
  > {
  loadOptions: (inputValue: string) => Promise<QSelectItem<TLabel, TValue>[]>;
}

export interface QAsyncSelectProps<
  TLabel extends React.ReactChild = string,
  TValue = string,
> extends QAsyncSelectBaseProps<false, TLabel, TValue> {
  onChange?: (selected: QSelectItem<TLabel, TValue> | null) => void;
  clearInputOnSelect?: boolean;
  children?: React.ReactNode;
}

export interface QAsyncMultiSelectProps<
  TLabel extends React.ReactChild = string,
  TValue = string,
> extends QAsyncSelectBaseProps<true, TLabel, TValue> {
  onChange?: (selected: QSelectItem<TLabel, TValue>[]) => void;
  children?: React.ReactNode;
}

export const QAsyncSelect = <
  TLabel extends React.ReactChild = string,
  TValue = string,
>(
  props: QAsyncSelectProps<TLabel, TValue>,
): React.ReactElement => {
  const { onChange, clearInputOnSelect, children } = props;

  const onChangeHandler = (
    selected: SingleValue<QSelectItem<TLabel, TValue>>,
  ) => {
    onChange && onChange(selected);
  };

  const { QSelectPlaceholder: placeholder } = usePartitionedChildren(
    children,
    'QSelectPlaceholder',
  );

  return (
    <AsyncSelect
      {...(baseProps as AsyncProps<
        QSelectItem<TLabel, TValue>,
        false,
        QGroup<TLabel, TValue>
      >)}
      {...props}
      menuPortalTarget={document.body}
      onChange={onChangeHandler}
      isMulti={false}
      value={clearInputOnSelect ? null : undefined}
      placeholder={placeholder.length ? placeholder : undefined}
      menuPlacement="auto"
    />
  );
};

export const QAsyncMultiSelect = <
  TLabel extends React.ReactChild = string,
  TValue = string,
>(
  props: QAsyncMultiSelectProps<TLabel, TValue>,
): React.ReactElement => {
  const { onChange, children } = props;

  type ItemType = QSelectItem<TLabel, TValue>;
  const onChangeHandler = (
    selected: MultiValue<
      QSelectItem<TLabel, TValue> | SingleValue<QSelectItem<TLabel, TValue>>
    >,
  ) => {
    const deNulled = selected ?? [];
    const selectedValues: ItemType[] = Array.isArray(deNulled)
      ? deNulled
      : [deNulled];
    onChange && onChange(selectedValues);
  };

  const { QSelectPlaceholder: placeholder } = usePartitionedChildren(
    children,
    'QSelectPlaceholder',
  );
  return (
    <AsyncSelect
      {...(baseProps as AsyncProps<
        QSelectItem<TLabel, TValue>,
        true,
        QGroup<TLabel, TValue>
      >)}
      {...props}
      menuPortalTarget={document.body}
      onChange={onChangeHandler}
      isMulti={true}
      menuPlacement="auto"
      placeholder={placeholder.length ? placeholder : undefined}
    />
  );
};
