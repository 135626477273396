import colors from '../../QTheme/colors';
export const Input = {
  variants: {
    outline: {
      field: {
        color: 'gray.700',
        borderColor: 'blue.200',
        borderRadius: '4px',
        _hover: {
          borderColor: 'blue.400',
        },
        _focus: {
          borderColor: 'blue.400',
          boxShadow: `${colors.blue['400']} 0px 0px 0px 1px`,
        },
        _invalid: {
          _focus: {
            borderColor: 'red.500',
            boxShadow: `${colors.red['500']} 0px 0px 0px 1px`,
          },
          borderColor: 'red.500',
        },
        _disabled: {
          color: 'gray.400',
          borderColor: 'gray.400',
        },
        _placeholder: {
          color: 'gray.500',
        },
        _readOnly: {
          color: 'gray.700',
          borderColor: 'gray.400',
          backgroundColor: 'gray.100',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};
