export const Tabs = {
  variants: {
    horizontal: {
      tab: {
        fontSize: 'sm',
        height: '36px',
        color: 'gray.700',
        _selected: {
          color: 'gray.900',
          borderBottom: '4px solid',
          borderColor: 'blue.500',
          marginBottom: '-1px',
          marginTop: '1px',
          paddingTop: '10px',
          _hover: {
            borderBottom: '4px solid',
            borderColor: 'blue.500',
            marginBottom: '-1px',
            marginTop: '1px',
            paddingTop: '3px',
          },
        },
        _hover: {
          color: 'gray.900',
          borderBottom: '1px solid',
          borderColor: 'blue.500',
          marginBottom: '-6px',
          marginTop: '1px',
          paddingBottom: '1px',
          paddingTop: '0px',
        },
        _focus: {
          boxShadow: 'none',
          outline: 'none',
        },
      },
      tablist: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
    },
    vertical: {
      tab: {
        fontSize: 'sm',
        height: '40px',
        width: '256px',
        justifyContent: 'left',
        color: 'gray.700',
        _selected: {
          color: 'gray.900',
          borderLeft: '4px solid',
          borderColor: 'blue.500',
          marginLeft: '-1px',
          paddingLeft: '13px',
          _hover: {
            color: 'gray.900',
            background: 'none',
          },
        },
        _hover: {
          color: 'gray.900',
          background: 'blackAlpha.50',
        },
        _focus: {
          boxShadow: 'none',
          outline: 'none',
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
