import React from 'react';

import { HTMLChakraProps, Text, TextProps } from '@chakra-ui/react';

export interface QTextProps
  extends HTMLChakraProps<'p'>,
    Pick<TextProps, 'fontSize' | 'color' | 'noOfLines' | 'casing'> {
  weight?: 'regular' | 'semibold';
}

export const QText: React.FC<QTextProps> = (props) => {
  return <Text variant={props.weight} {...props} />;
};

QText.displayName = 'QText';
