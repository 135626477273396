import React from 'react';
import { Icon } from '@chakra-ui/react';

export const OneDrive: React.FC = (props) => {
  return (
    <Icon viewBox="0 0 20 20" color="currentColor" {...props}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2766_32298)">
          <g clipPath="url(#clip1_2766_32298)">
            <path
              d="M7.62694 7.15468L7.62714 7.154L11.8257 9.66641L14.3275 8.61462C14.8359 8.39505 15.3841 8.28247 15.9379 8.28301C16.0302 8.28301 16.1214 8.28719 16.2121 8.29324C15.9114 7.12152 15.2764 6.0623 14.3844 5.24431C13.4924 4.42631 12.3818 3.88475 11.1875 3.68543C9.99323 3.48612 8.76672 3.63764 7.65702 4.12157C6.54732 4.60551 5.6022 5.40102 4.93652 6.41145C4.95791 6.41118 4.97897 6.40984 5.00041 6.40984C5.92832 6.40858 6.83808 6.66658 7.62694 7.15468Z"
              fill="#0364B8"
            />
            <path
              d="M7.62672 7.15388L7.62653 7.15457C6.83767 6.66646 5.9279 6.40846 5 6.40972C4.97856 6.40972 4.95747 6.41106 4.93611 6.41133C4.02792 6.42256 3.13996 6.68082 2.36766 7.15835C1.59536 7.63587 0.967906 8.31462 0.552732 9.12164C0.137557 9.92866 -0.0496439 10.8335 0.0112457 11.7388C0.0721353 12.6441 0.378814 13.5158 0.898324 14.26L4.60082 12.7035L6.24671 12.0115L9.91142 10.4709L11.8252 9.66629L7.62672 7.15388Z"
              fill="#0078D4"
            />
            <path
              d="M16.2112 8.29294C16.1204 8.28688 16.0292 8.2827 15.937 8.2827C15.3831 8.28216 14.835 8.39517 14.3267 8.61474L11.8247 9.66611L12.5502 10.1002L14.9283 11.5233L15.9658 12.1442L19.5135 14.2671C19.8359 13.6693 20.0029 13.0002 19.9994 12.3211C19.9958 11.6421 19.8218 10.9748 19.4932 10.3803C19.1646 9.78587 18.692 9.28328 18.1186 8.9186C17.5452 8.55392 16.8894 8.33879 16.2112 8.29294Z"
              fill="#1490DF"
            />
            <path
              d="M15.9665 12.1444L14.9289 11.5236L12.5508 10.1005L11.8254 9.6664L9.91153 10.471L6.24683 12.0116L4.60094 12.7036L0.898438 14.2601C1.35854 14.921 1.97191 15.4608 2.68616 15.8334C3.40041 16.2061 4.19432 16.4005 5.00011 16.4001H15.9376C16.671 16.4003 17.3907 16.2021 18.0204 15.8266C18.6501 15.4512 19.1663 14.9124 19.5142 14.2674L15.9665 12.1444Z"
              fill="#28A8EA"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2766_32298">
            <rect width="20" height="20" rx="1.6" fill="white" />
          </clipPath>
          <clipPath id="clip1_2766_32298">
            <rect
              width="20"
              height="12.8"
              fill="white"
              transform="translate(0 3.60008)"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

export default OneDrive;
