import { z } from 'zod';

export const Index = z.enum([
  // 'audits',
  'change-management',
  'documents',
  'events',
  'suppliers',
  'users',
]);

export type Index = z.infer<typeof Index>;

export const Domain = z.enum([
  Index.Enum['change-management'],
  Index.Enum.documents,
  Index.Enum.suppliers,
  'quality-events',
  Index.enum.users,
]);

export type Domain = z.infer<typeof Domain>;

const baseResult = z.object({
  qri: z.string(),
  title: z.string(),
  code: z.string(),
  companyId: z.number(),
  owner: z.string(),
});

const documentResult = baseResult.merge(
  z.object({
    domain: z.literal(Domain.enum.documents),
    domainType: z.literal('document'),
    id: z.number(),
    matrixId: z.string().optional(),
    link: z.string(),
    version: z.string(),
    status: z.string(),
    author: z.string(),
  }),
);

const eventResult = baseResult.merge(
  z.object({
    domain: z.literal('quality-events'),
    domainType: z.literal('quality-event'),
    id: z.number(),
  }),
);

const supplierResult = baseResult
  .merge(
    z.object({
      domain: z.literal(Domain.enum.suppliers),
      domainType: z.literal('supplier'),
      id: z.string(),
      version: z.number(),
      status: z.string(),
    }),
  )
  .omit({ code: true });

const changeControlResult = baseResult
  .merge(
    z.object({
      id: z.string(),
      domain: z.literal(Domain.enum['change-management']),
      domainType: z.literal('change-control'),
      title: z.string().nullable(),
      change_control_reference: z.string(),
    }),
  )
  .omit({ code: true });

const userResult = z.object({
  id: z.number(),
  domain: z.literal(Domain.enum['users']),
  domainType: z.literal('user'),
  full_name: z.string(),
  email: z.string(),
  qri: z.string(),
});

const searchResult = z.discriminatedUnion('domain', [
  documentResult,
  eventResult,
  supplierResult,
  changeControlResult,
  userResult,
]);

export type DocumentResult = z.infer<typeof documentResult>;
export type EventResult = z.infer<typeof eventResult>;
export type SupplierResult = z.infer<typeof supplierResult>;
export type ChangeControlResult = z.infer<typeof changeControlResult>;
export type UserResult = z.infer<typeof userResult>;

// NOTE: You might read the code below and think "why not just use a generic type?"
// And the answer is that at best, zod will force the results field to be optional,
// and at worst, you'll drive yourself mad trying to get it to work.
// It's not worth your time.

export const SearchResponse = z.object({
  total: z.number(),
  totalRelation: z.union([z.literal('eq'), z.literal('gte')]),
  page: z.number(),
  size: z.number(),
  results: searchResult.array(),
});

export type SearchResponse = z.infer<typeof SearchResponse>;

export const SearchResponseForDocuments = SearchResponse.extend({
  results: z.array(documentResult),
});
export const SearchResponseForEvents = SearchResponse.extend({
  results: z.array(eventResult),
});
export const SearchResponseForSuppliers = SearchResponse.extend({
  results: z.array(supplierResult),
});
export const SearchResponseForChangeManagement = SearchResponse.extend({
  results: z.array(changeControlResult),
});
export const SearchResponseForUsers = SearchResponse.extend({
  results: z.array(userResult),
});

/**
 * Returns the correct search response schema for the given entity.
 * Depending on the entity supplied, the `results` field will be different.
 *
 * Will throw an error if the entity is not recognized.
 */
export function SearchResponseSchemaFor(
  entity: 'documents',
): typeof SearchResponseForDocuments;
export function SearchResponseSchemaFor(
  entity: 'events',
): typeof SearchResponseForEvents;
export function SearchResponseSchemaFor(
  entity: 'suppliers',
): typeof SearchResponseForSuppliers;
export function SearchResponseSchemaFor(
  entity: 'change-management',
): typeof SearchResponseForChangeManagement;
export function SearchResponseSchemaFor(
  entity: 'users',
): typeof SearchResponseForUsers;
export function SearchResponseSchemaFor(
  entity: Index,
):
  | typeof SearchResponseForDocuments
  | typeof SearchResponseForEvents
  | typeof SearchResponseForSuppliers
  | typeof SearchResponseForChangeManagement
  | typeof SearchResponseForUsers
  | never;
export function SearchResponseSchemaFor(
  entity: Index,
):
  | typeof SearchResponseForDocuments
  | typeof SearchResponseForEvents
  | typeof SearchResponseForSuppliers
  | typeof SearchResponseForChangeManagement
  | typeof SearchResponseForUsers {
  switch (entity) {
    case Index.enum.documents:
      return SearchResponseForDocuments;
    case Index.enum.events:
      return SearchResponseForEvents;
    case Index.enum.suppliers:
      return SearchResponseForSuppliers;
    case Index.enum['change-management']:
      return SearchResponseForChangeManagement;
    case Index.enum.users:
      return SearchResponseForUsers;
    default:
      throw new Error(
        `No search response schema defined for entity: ${entity}`,
      );
  }
}
