import type { AccessorFn, ColumnMeta, RowData } from '@tanstack/react-table';
import type { To } from 'react-router';
import { QColumnWidthDef } from '../Columns';
import { CellProps } from './types';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> extends QColumnWidthDef {
    link?: {
      urlAccessor: AccessorFn<TData, To>;
      isExternal: boolean | AccessorFn<TData, boolean>;
    };
    date?: {
      includeTime: boolean;
    };
    menu?: {
      items: React.ReactElement | readonly React.ReactElement[];
    };
  }
}

export class MissingMetaError extends Error {
  constructor(column: string, field: keyof ColumnMeta<unknown, unknown>) {
    super(
      `Missing Meta: Column "${column}" is missing the "${field}" meta field.`,
    );

    // Set the prototype explicitly.
    // https://github.com/microsoft/TypeScript-wiki/blob/81fe7b91664de43c02ea209492ec1cea7f3661d0/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, MissingMetaError.prototype);
  }
}

/**
 * Gets the value of a meta field from a column.
 * Throws an error if the field is missing.
 */
export const getMetaField = <
  TData extends RowData,
  TValue,
  TKey extends keyof ColumnMeta<TData, TValue>,
>(
  column: CellProps<TData, TValue>['column'],
  key: TKey,
): NonNullable<ColumnMeta<TData, TValue>[TKey]> => {
  const val = column.columnDef.meta?.[key];
  if (val === null || val === undefined) {
    throw new MissingMetaError(column.id, key);
  }
  return val;
};
