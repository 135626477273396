export const Alert = {
  baseStyle: {
    title: {
      textTransform: 'lowercase',
      '&:first-letter': { textTransform: 'uppercase' },
    },
    description: {
      textTransform: 'lowercase',
      '&:first-letter': { textTransform: 'uppercase' },
    },
  },
  variants: {
    sm: {
      fontSize: '20px',
    },
  },
};
