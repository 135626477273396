import { QButtonGroup, QButtonProps } from '../..';
import React from 'react';

export type QModalActionsProps = {
  children:
    | React.ReactElement<QButtonProps>
    | Array<React.ReactElement<QButtonProps>>;
};

export const QModalActions: React.FC<QModalActionsProps> = ({ children }) => {
  return <QButtonGroup>{children}</QButtonGroup>;
};

QModalActions.displayName = 'QModalActions';
