import React from 'react';
import { Badge, HTMLChakraProps } from '@chakra-ui/react';
import { QMarginProps } from '../..';

export type QBadgeProps = QMarginProps & HTMLChakraProps<'span'>;

export const QBadge: React.FC<QBadgeProps> = (props) => {
  return <Badge {...props} />;
};

QBadge.displayName = 'QBadge';
