import { QBox, QCenter, QStack } from '../../QLayouts';
import React from 'react';
import { QHeading, QText, QButton, QButtonProps } from '../../QAtoms';

type QEmptyStateButtonProps = QButtonProps & { label: string };

export type QEmptyStateProps = {
  title: string;
  subtitle?: string | Array<string>;
  primaryButtonProps?: QEmptyStateButtonProps;
  secondaryButtonProps?: QEmptyStateButtonProps;
};

export const QEmptyState: React.FC<QEmptyStateProps> = (props) => {
  const { title, subtitle, primaryButtonProps, secondaryButtonProps } = props;
  const subtitles = (Array.isArray(subtitle) ? subtitle : [subtitle]).filter(
    (item) => item !== undefined,
  );

  return (
    <QCenter>
      <QBox
        maxWidth="640px"
        py={10}
        px={6}
        display="flex"
        flexDirection="column"
        textAlign="center"
      >
        <QHeading size="md" as="h2">
          {title}
        </QHeading>
        {subtitles.length > 0 ? (
          <QBox mt={4}>
            {subtitles.map((subtitle) => (
              <QText fontSize="sm" color="gray.500" key={subtitle}>
                {subtitle}
              </QText>
            ))}
          </QBox>
        ) : null}
        {secondaryButtonProps || primaryButtonProps ? (
          <QCenter>
            <QStack direction="row" mt={10}>
              {secondaryButtonProps ? (
                <QButton {...secondaryButtonProps} variant="outline">
                  {secondaryButtonProps.label}
                </QButton>
              ) : null}
              {primaryButtonProps ? (
                <QButton {...primaryButtonProps} variant="solid">
                  {primaryButtonProps.label}
                </QButton>
              ) : null}
            </QStack>
          </QCenter>
        ) : null}
      </QBox>
    </QCenter>
  );
};
